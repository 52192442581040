import { useCallback, useState, type FC, type PropsWithChildren } from 'react';
import { NotificationsContext } from './NotificationsContext';
import type { NotificationDetails, NotificationHandle, NotificationOptions } from './types';

export const Notifications: FC<PropsWithChildren> = ({ children }) => {
  const [notifications, setNotifications] = useState<readonly NotificationDetails[]>([]);

  const notify = useCallback(({ message, action, vibe = 'informative' }: NotificationOptions) => {
    const id = generateNotificationId();
    const notification: NotificationDetails = {
      id,
      message,
      action,
      vibe,
    };
    setNotifications(notifications => [...notifications, notification]);
    return id;
  }, []);

  const clear = useCallback((id: NotificationHandle) => {
    setNotifications(notifications => notifications.filter(notification => notification.id !== id));
  }, []);

  return (
    <NotificationsContext.Provider
      value={{
        notify,
        clear,
        notifications,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

function generateNotificationId(): NotificationHandle {
  return crypto.randomUUID() as NotificationHandle;
}
