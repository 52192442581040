import { type PaletteMode, ThemeProvider } from '@mui/material';
import { type FC, type RefObject, useCallback, useEffect, useMemo } from 'react';
import { useLocalStorage } from 'react-use';
import { muiDarkTheme } from '../../theme/darkTheme';
import { muiLightTheme } from '../../theme/lightTheme';
import { ConfigContext, type ConfigContextProps } from './ConfigContext';
import { darkTheme } from './darkTheme';
import { lightTheme } from './lightTheme';

const isRefObject = (ref: unknown) =>
  ref !== null && typeof ref === 'object' && Object.prototype.hasOwnProperty.call(ref, 'current');

export interface ConfigProviderProps {
  reference?: RefObject<any> | HTMLElement;
  theme?: PaletteMode;
  children?: React.ReactNode;
}

export const ConfigProvider: FC<ConfigProviderProps> = ({ children, theme, reference = document.body }) => {
  const [userTheme, setUserTheme] = useLocalStorage<PaletteMode>('@query/theme', theme || 'light');

  const activeTheme = useMemo(() => ((theme || userTheme) === 'dark' ? darkTheme : lightTheme), [theme, userTheme]);

  const applyTheme = useCallback(
    (ctx: ConfigContextProps) => {
      const element = isRefObject(reference) ? (reference as RefObject<any>).current : reference;

      for (const prop in ctx.variables) {
        element.style.setProperty(prop, ctx.variables[prop]);
      }

      for (const prop in ctx.colors) {
        for (const k in ctx.colors[prop]) {
          element.style.setProperty(`--color-${prop}-${k}`, ctx.colors[prop][k]);
        }
      }
    },
    [reference]
  );

  useEffect(() => {
    applyTheme(activeTheme as any);
  }, [activeTheme, reference, applyTheme]);

  const values: any = useMemo(
    () => ({
      ...activeTheme,
      setTheme: setUserTheme,
    }),
    [activeTheme, setUserTheme]
  );

  return (
    <ConfigContext.Provider value={values}>
      <ThemeProvider theme={values.name === 'dark' ? muiDarkTheme : muiLightTheme}>{children}</ThemeProvider>
    </ConfigContext.Provider>
  );
};
