import type { PaletteMode } from '@mui/material';
import { createContext, useContext } from 'react';

export interface ConfigContextProps {
  name: PaletteMode;
  colors: {
    [key: string]: any;
  };
  variables: {
    [key: string]: string;
  };
  setTheme: (theme: PaletteMode) => void;
}

export const ConfigContext = createContext<ConfigContextProps>({
  name: 'light',
  variables: {},
  colors: {},
  setTheme: () => undefined,
});

export const useConfig = () => {
  const context = useContext(ConfigContext);

  if (context === undefined) {
    throw new Error('`useConfig` hook must be used within a `ConfigContext` component');
  }

  return context;
};
