import { ApolloLink } from '@apollo/client';

export const traceHeaderLink = new ApolloLink((operation, forward) =>
  forward(operation).map(response => {
    const context = operation.getContext();
    const traceIdHeader = context.response.headers.get('X-Amzn-Trace-Id');
    sessionStorage.setItem('traceId', traceIdHeader);
    return response;
  })
);
