export enum FlagType {
  BOOLEAN = 'BOOLEAN',
  STRING = 'STRING',
  NUMBER = 'NUMBER',
  JSON = 'JSON',
}

interface FlagBase {
  readonly key: string;
  readonly type: FlagType;
  readonly metadata?: unknown;
}

export interface BooleanFlag extends FlagBase {
  readonly type: FlagType.BOOLEAN;
  readonly value: boolean;
}

export interface StringFlag extends FlagBase {
  readonly type: FlagType.STRING;
  readonly value: string;
}

export interface NumberFlag extends FlagBase {
  readonly type: FlagType.NUMBER;
  readonly value: number;
}

export interface JsonFlag extends FlagBase {
  readonly type: FlagType.JSON;
  readonly value: unknown;
}

export type Flag = BooleanFlag | StringFlag | NumberFlag | JsonFlag;

export type FlagOfType<T extends FlagType> = T extends FlagType.BOOLEAN
  ? BooleanFlag
  : T extends FlagType.STRING
    ? StringFlag
    : T extends FlagType.NUMBER
      ? NumberFlag
      : JsonFlag;

export type FlagListener = (value: Flag | undefined) => void;

export type GlobalFlagListener = (determinations: ReadonlyMap<string, Flag>) => void;

export interface FlagContextValue {
  /**
   * Get the determinations for all features. Depending on the provider, these are likely already
   * determined and cached, and thus you should get back a reference-stable map of the flag keys
   * and their determined values.
   *
   * A note for implementations: If no flags have changed, you should return the same map reference
   * between calls to this method. If a flag has changed, a new map should be returned, but the
   * flags that have not changed should be the same references as the previous map.
   */
  getDeterminations(): ReadonlyMap<string, Flag>;
  /**
   * Subscribes a function to be called whenever the value of a feature changes. This is leveraged
   * by the useFlag hook to be able to re-render components when the value of a feature changes.
   */
  addFeatureListener(feature: string, listener: FlagListener): void;
  /**
   * Unsubscribes a listener from a feature. This is used to clean up listeners when a component
   * is unmounted.
   */
  removeFeatureListener(feature: string, listener: FlagListener): void;
  /**
   * Subscribes a function to be called whenever the value of any feature changes. This is used by
   * the useAllFlags hook to be able to re-render components when the value of any feature changes.
   */
  addGlobalListener(listener: GlobalFlagListener): void;
  /**
   * Unsubscribes a listener from all features. This is used to clean up listeners when a component
   * is unmounted.
   */
  removeGlobalListener(listener: GlobalFlagListener): void;
}
