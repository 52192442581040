import { ApolloClient, ApolloLink, ApolloProvider as DefaultApolloProvider } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { useAuth0 } from '@auth0/auth0-react';
import { type FC, type PropsWithChildren, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { authLink } from './authLink';
import { cache } from './cache';
import { errorLink } from './errorLink';
import { httpLink, searchHttpLink } from './httpLink';
import { traceHeaderLink } from './traceHeaderLink';
import { wsLink } from './wsLink';

export const ApolloProvider: FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();

  const client = useMemo(() => {
    const link = ApolloLink.split(
      operation => operation.getContext().clientName === 'search',
      ApolloLink.split(
        ({ query }) => {
          const definition = getMainDefinition(query);
          return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
        },
        wsLink(),
        ApolloLink.from([errorLink(navigate), authLink(getAccessTokenSilently), traceHeaderLink, searchHttpLink])
      ),
      ApolloLink.from([errorLink(navigate), authLink(getAccessTokenSilently), httpLink])
    );

    return new ApolloClient({
      link,
      cache,
      connectToDevTools: true,
      defaultOptions: {
        watchQuery: {
          errorPolicy: 'ignore',
          notifyOnNetworkStatusChange: true,
        },
        query: {
          errorPolicy: 'all',
          notifyOnNetworkStatusChange: true,
        },
        mutate: {
          errorPolicy: 'all',
        },
      },
    });
  }, [navigate, getAccessTokenSilently]);

  return <DefaultApolloProvider client={client}>{children}</DefaultApolloProvider>;
};
