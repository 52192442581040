import { FullStory, init } from '@fullstory/browser';
import { fullStoryIntegration } from '@sentry/fullstory';
import * as Sentry from '@sentry/react';
import pkg from '../../../package.json';

const excludedUserAgentPhrases = ['headless', 'pingdom', 'playwright', 'phantom', 'selenium', 'puppeteer'];

const isExcludedAgent = excludedUserAgentPhrases.some(phrase => navigator.userAgent.toLowerCase().includes(phrase));

// Add in sentry if not running on local (and not an uptime or e2e bot)
if (import.meta.env.PROD && !isExcludedAgent) {
  let environment = 'prod';
  if (window.location.origin.includes('dev')) {
    environment = 'dev';
  } else if (window.location.origin.includes('test')) {
    environment = 'test';
  } else if (window.location.origin.includes('stage')) {
    environment = 'stage';
  }

  // Initialize FullStory
  init({ orgId: import.meta.env.VITE_FULLSTORY_ORG });

  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    autoSessionTracking: true,
    integrations: [
      fullStoryIntegration(import.meta.env.VITE_SENTRY_SLUG, {
        client: FullStory,
      }),
      Sentry.browserTracingIntegration(),
    ],
    ignoreErrors: ['ResizeObserver'], // Ignoring ResizeObserver loop completed with undelivered notifications.
    denyUrls: ['fullstory.com'],
    release: pkg.version,
    tracesSampleRate: environment === 'dev' ? 1.0 : 0.25,
    environment,
  });
}
