import { Box, Typography } from '@mui/material';

export function ErrorDevDisplay({ error }: { error: unknown }) {
  return (
    <Box>
      <Typography variant="h2">Error details (dev-only)</Typography>
      <ErrorDevDisplayDetails error={error} />
    </Box>
  );
}

export function ErrorDevDisplayDetails({ error }: { error: unknown }) {
  if (import.meta.env.PROD) {
    return null;
  }

  if (typeof error === 'object' && error !== null) {
    if (error instanceof Error) {
      return (
        <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
          {error.stack && <div>{error.stack}</div>}
          {!!error.cause && (
            <div>
              <div>Inner Error:</div>
              <ErrorDevDisplayDetails error={error.cause} />
            </div>
          )}
        </pre>
      );
    }
    return <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>{JSON.stringify(error, null, 2)}</pre>;
  }
  return <div>{String(error)}</div>;
}
