import type { UserProfile } from 'core/metadata-graphql';
import { createContext, useContext } from 'react';

export interface AuthContextProps {
  user: UserProfile | null;
  hasAdminRole: boolean;
  canResetPassword?: boolean;
  isAuthenticated?: boolean;
  isLoading?: boolean;
  login: () => void;
  logout: (redirect?: boolean) => void;
  resetPassword(email: string): void;
  refetchUser: () => void;
  error?: Error;
}

const AuthContext = createContext<AuthContextProps>({
  user: null,
  hasAdminRole: false,
  login: () => undefined,
  logout: async () => undefined,
  resetPassword: () => undefined,
  refetchUser: () => undefined,
});

export const AuthProvider = AuthContext.Provider;

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error('`useAuth` hook must be used within a `AuthProvider` component');
  }

  return context;
};
