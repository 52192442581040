import * as React from "react";
import { forwardRef } from "react";
const SvgMark = (props, ref) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 134.644 100.399", style: {
  enableBackground: "new 0 0 134.644 100.399"
}, xmlSpace: "preserve", ref, ...props }, /* @__PURE__ */ React.createElement("path", { d: "M48.478 84.591c-18.871-1.054-33.913-16.696-33.913-35.827s15.041-34.772 33.912-35.826a52.505 52.505 0 0 1 10.892-8.83 45.712 45.712 0 0 0-8.877-.88c-25.147 0-45.534 20.389-45.534 45.536 0 12.945 5.386 24.578 14.016 32.857L2.351 98.37l.109.188 28.456-8.707c5.933 2.824 12.552 4.449 19.576 4.449 3.04 0 6.005-.311 8.878-.879a52.484 52.484 0 0 1-10.892-8.83", style: {
  fill: "#1ec1dd"
} }), /* @__PURE__ */ React.createElement("path", { d: "M109.121 76.685c-6.174 4.999-14.021 8.009-22.565 8.009-19.812 0-35.929-16.118-35.929-35.929s16.117-35.928 35.929-35.928c19.81 0 35.927 16.117 35.927 35.928 0 7.119-2.107 13.744-5.693 19.333l7.256 6.493a45.248 45.248 0 0 0 8.044-25.826c0-25.147-20.387-45.536-45.534-45.536-25.148 0-45.537 20.388-45.537 45.536 0 25.149 20.389 45.536 45.537 45.536 7.024 0 13.643-1.625 19.576-4.449l26.065 7.976.213-.301-23.289-20.842z", style: {
  fill: "#02586e"
} }));
const ForwardRef = forwardRef(SvgMark);
export default ForwardRef;
