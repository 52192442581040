import { createContext, useContext } from 'react';
import type { NotificationsContextType } from './types';

export const NotificationsContext = createContext<NotificationsContextType>({
  notify: () => {
    throw new Error('notify must be called within a Notifications context provider');
  },
  clear: () => {},
  notifications: [],
});

export function useNotifications() {
  return useContext(NotificationsContext);
}
