import { InMemoryCache, type StoreObject, gql } from '@apollo/client';
import type { PlatformConnectionConfiguration } from 'core/metadata-graphql';

export const cache = new InMemoryCache();

type CreateConnectionCacheUpdaterData = {
  data?: { createPlatformConnectionConfiguration: PlatformConnectionConfiguration };
};

export const createConnectionCacheUpdater = (cache: InMemoryCache, { data }: CreateConnectionCacheUpdaterData) => {
  const createPlatformConnectionConfiguration = data?.createPlatformConnectionConfiguration;

  if (!createPlatformConnectionConfiguration) {
    // The call wasn't successful. Don't update the cache.
    return;
  }
  cache.modify({
    fields: {
      platformConnectionConfiguration(existingConfigurations = []) {
        const data = {
          ...createPlatformConnectionConfiguration,
          connectionConfiguration: createPlatformConnectionConfiguration.connectionConfigurationV2, // TODO: Remove this after connectionConfigurationV2 is removed
        };
        delete data['connectionConfigurationV2'];
        const newConfiguration = cache.writeFragment({
          data,
          fragment: gql`
            fragment NewPlatformConnectionConfiguration on PlatformConnectionConfiguration {
              connectionConfiguration
              id
              isActive
              name
              platformId
              platformName
              teamId
              teamName
              schemaMapping
            }
          `,
        });
        return [...existingConfigurations, newConfiguration];
      },
    },
  });
};

type UpdateConnectionCacheUpdaterData = {
  data?: { updatePlatformConnectionConfiguration: PlatformConnectionConfiguration };
};
// TODO: Remove this whole function after connectionConfigurationV2 is removed
export const updateConnectionCacheUpdater = (cache: InMemoryCache, { data }: UpdateConnectionCacheUpdaterData) => {
  const updatePlatformConnectionConfiguration = data?.updatePlatformConnectionConfiguration;

  if (!updatePlatformConnectionConfiguration) {
    // The call wasn't successful. Don't update the cache.
    return;
  }
  cache.modify({
    fields: {
      platformConnectionConfiguration(existingConfigurations = []) {
        const data = {
          ...updatePlatformConnectionConfiguration,
          connectionConfiguration: updatePlatformConnectionConfiguration.connectionConfigurationV2,
        };
        delete data['connectionConfigurationV2'];
        const newConfiguration = cache.modify({
          id: cache.identify(data as StoreObject),
          fields: {
            connectionConfiguration() {
              return data.connectionConfiguration;
            },
            isActive() {
              return data.isActive;
            },
            name() {
              return data.name;
            },
            schemaMapping() {
              return data.schemaMapping;
            },
          },
        });
        return [...existingConfigurations, newConfiguration];
      },
    },
  });
};

type DeleteConnectionCacheUpdaterData = { data?: { deletePlatformConnectionConfiguration: number } };

export const deleteConnectionCacheUpdater = (cache: InMemoryCache, { data }: DeleteConnectionCacheUpdaterData) => {
  const deletePlatformConnectionConfiguration = data?.deletePlatformConnectionConfiguration;

  if (!deletePlatformConnectionConfiguration) {
    // The call wasn't successful. Don't update the cache.
    return;
  }

  cache.modify({
    fields: {
      platformConnectionConfiguration(existingConfigurations = []) {
        const deletedRefName = `PlatformConnectionConfiguration:${deletePlatformConnectionConfiguration}`;
        return deletePlatformConnectionConfiguration
          ? existingConfigurations.filter((config: { __ref: string }) => config.__ref !== deletedRefName)
          : existingConfigurations;
      },
    },
  });
};
