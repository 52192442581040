import { setContext } from '@apollo/client/link/context';

export const authLink = (getTokenSilently: () => Promise<string>) =>
  setContext(async () => {
    const token = await getTokenSilently();
    // The JWT is also needed on the cookie because web sockets don't support Authorization headers
    if (!document.cookie.includes(token)) {
      document.cookie = `JWT=${token}`;
    }
    return {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  });
