import { createTheme, type CSSInterpolation, type ThemeOptions } from '@mui/material';
import type {} from '@mui/x-date-pickers-pro/themeAugmentation';
import {
  DARK_CAUTION_BACKGROUND,
  DARK_CONTAINER_BACKGROUND,
  DARK_CONTAINER_BORDERS,
  DARK_FEEDBACK_CAUTION,
  DARK_FEEDBACK_INFO,
  DARK_FEEDBACK_NEGATIVE,
  DARK_FEEDBACK_POSITIVE,
  DARK_INFO_BACKGROUND,
  DARK_INTERACTIVE_ACTIVE,
  DARK_INTERACTIVE_CAUTION_HOVER,
  DARK_INTERACTIVE_DESTRUCTIVE,
  DARK_INTERACTIVE_DESTRUCTIVE_HOVER,
  DARK_INTERACTIVE_DESTRUCTIVE_PRESSED,
  DARK_INTERACTIVE_INFO_HOVER,
  DARK_INTERACTIVE_NEGATIVE_HOVER,
  DARK_INTERACTIVE_PRIMARY_HOVER,
  DARK_INTERACTIVE_PRIMARY_PRESSED,
  DARK_INTERACTIVE_SECONDARY_HOVER,
  DARK_INTERACTIVE_SECONDARY_PRESSED,
  DARK_INTERACTIVE_SELECTED,
  DARK_MODAL_BACKGROUND,
  DARK_NEGATIVE_BACKGROUND,
  DARK_PAGE_BACKGROUND,
  DARK_RULES,
  DARK_TEXT_BUTTON,
  DARK_TEXT_DISABLED,
  DARK_TEXT_NEGATIVE,
  DARK_TEXT_PRIMARY,
  DARK_TEXT_SECONDARY,
  DARK_TEXT_TERTIARY,
  LIGHT_INFO,
  LIGHT_MODAL_BACKGROUND,
  LIGHT_NEGATIVE,
  LIGHT_POSITIVE,
  LIGHT_TEXT_PRIMARY,
  LIGHT_TEXT_SECONDARY,
} from './colors';
import { largeButtonSize, mediumButtonSize, muiTheme, smallButtonSize } from './theme';

const iconButtonDarkColors: CSSInterpolation = {
  color: DARK_TEXT_SECONDARY,
  ':hover': {
    color: DARK_TEXT_PRIMARY,
    backgroundColor: DARK_INTERACTIVE_SECONDARY_HOVER,
  },
  ':active': {
    color: DARK_TEXT_PRIMARY,
    backgroundColor: DARK_INTERACTIVE_SECONDARY_PRESSED,
  },
  ':disabled': {
    color: DARK_TEXT_DISABLED,
  },
};

export const muiDarkTheme = createTheme(muiTheme, {
  typography: {
    link: {
      color: DARK_INTERACTIVE_ACTIVE,
    },
  },
  palette: {
    mode: 'dark',
    primary: {
      main: DARK_INTERACTIVE_ACTIVE,
      light: DARK_INTERACTIVE_PRIMARY_HOVER,
      dark: DARK_INTERACTIVE_PRIMARY_PRESSED,
    },
    secondary: {
      main: DARK_INTERACTIVE_ACTIVE,
      light: DARK_INTERACTIVE_SECONDARY_HOVER,
      dark: DARK_INTERACTIVE_SECONDARY_PRESSED,
    },
    error: {
      main: DARK_INTERACTIVE_DESTRUCTIVE,
      light: DARK_INTERACTIVE_DESTRUCTIVE_HOVER,
      dark: DARK_INTERACTIVE_DESTRUCTIVE_PRESSED,
    },
    success: {
      main: DARK_FEEDBACK_POSITIVE,
      light: DARK_INTERACTIVE_SECONDARY_HOVER,
      dark: DARK_INTERACTIVE_SECONDARY_PRESSED,
    },
    info: {
      main: DARK_INTERACTIVE_ACTIVE,
      light: DARK_INTERACTIVE_SECONDARY_HOVER,
      dark: DARK_INTERACTIVE_SECONDARY_PRESSED,
    },
    warning: {
      main: DARK_INTERACTIVE_ACTIVE,
      light: DARK_INTERACTIVE_SECONDARY_HOVER,
      dark: DARK_INTERACTIVE_SECONDARY_PRESSED,
    },
    text: {
      disabled: DARK_TEXT_DISABLED,
      primary: DARK_TEXT_PRIMARY,
      secondary: DARK_TEXT_SECONDARY,
    },
    background: {
      default: DARK_PAGE_BACKGROUND,
      paper: DARK_CONTAINER_BACKGROUND,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: DARK_PAGE_BACKGROUND,
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          '&.tertiary': {
            color: DARK_TEXT_TERTIARY,
          },
          '&.secondary': {
            color: DARK_TEXT_SECONDARY,
          },
          '&.disabled': {
            color: DARK_TEXT_DISABLED,
          },
        },
        link: {
          '&.current': {
            color: DARK_INTERACTIVE_PRIMARY_PRESSED,
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          ':hover': {
            backgroundColor: DARK_INTERACTIVE_SECONDARY_HOVER,
          },
          '&.Mui-disabled > .MuiAccordionSummary-content': {
            color: DARK_TEXT_DISABLED,
          },
          '&.Mui-disabled > .MuiAccordionSummary-expandIconWrapper': {
            color: DARK_TEXT_DISABLED,
          },
          '&.Mui-disabled > .MuiAccordionSummary-content > p': {
            color: DARK_TEXT_DISABLED,
          },
        },
        expandIconWrapper: {
          color: DARK_TEXT_PRIMARY,
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'menu' },
          style: {
            padding: '0.625rem 1rem',
          },
        },
        {
          props: { variant: 'text' },
          style: {
            padding: 0,
            ':hover': {
              backgroundColor: 'transparent',
              color: DARK_INTERACTIVE_PRIMARY_HOVER,
              ' svg': {
                fill: DARK_INTERACTIVE_PRIMARY_HOVER,
              },
            },
            ':disabled': {
              color: DARK_TEXT_DISABLED,
            },
          },
        },
      ],
      styleOverrides: {
        root: {
          '&.secondary': {
            color: DARK_TEXT_SECONDARY,
          },
        },
        menu: {
          backgroundColor: DARK_CONTAINER_BACKGROUND,
          color: DARK_TEXT_SECONDARY,
          ':hover': {
            backgroundColor: DARK_INTERACTIVE_SECONDARY_HOVER,
            color: DARK_TEXT_PRIMARY,
          },
          ':active': {
            backgroundColor: DARK_INTERACTIVE_SECONDARY_PRESSED,
            color: DARK_TEXT_PRIMARY,
          },
          ':disabled': {
            color: DARK_TEXT_DISABLED,
          },
        },
        contained: {
          backgroundColor: DARK_INTERACTIVE_ACTIVE,
          color: DARK_TEXT_BUTTON,
          ':hover': { backgroundColor: DARK_INTERACTIVE_PRIMARY_HOVER },
          ':active': { backgroundColor: DARK_INTERACTIVE_PRIMARY_PRESSED },
          ':disabled': {
            backgroundColor: DARK_TEXT_DISABLED,
            color: DARK_TEXT_TERTIARY,
          },
        },
        outlined: {
          color: DARK_INTERACTIVE_ACTIVE,
          borderColor: DARK_INTERACTIVE_SECONDARY_PRESSED,
          ':hover': {
            backgroundColor: DARK_INTERACTIVE_SECONDARY_HOVER,
            borderColor: DARK_INTERACTIVE_ACTIVE,
            color: DARK_TEXT_PRIMARY,
          },
          ':active': {
            backgroundColor: DARK_INTERACTIVE_SECONDARY_PRESSED,
            borderColor: DARK_INTERACTIVE_ACTIVE,
            color: DARK_TEXT_PRIMARY,
          },
          ':disabled': {
            color: DARK_TEXT_DISABLED,
            borderColor: DARK_RULES,
          },
        },
        sizeLarge: largeButtonSize,
        sizeMedium: mediumButtonSize,
        sizeSmall: smallButtonSize,
      },
    },
    MuiIconButton: {
      styleOverrides: {
        sizeMedium: {
          ...iconButtonDarkColors,
          padding: '0.25rem',
          borderRadius: '1rem',
        },
        sizeSmall: {
          ...iconButtonDarkColors,
          padding: '0.1875rem',
          borderRadius: '0.75rem',
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: DARK_TEXT_TERTIARY,
          '&.Mui-checked': {
            color: DARK_INTERACTIVE_ACTIVE,
            ':hover': {
              backgroundColor: DARK_INTERACTIVE_SECONDARY_HOVER,
              color: DARK_INTERACTIVE_PRIMARY_HOVER,
            },
            ':active': {
              backgroundColor: DARK_INTERACTIVE_SECONDARY_PRESSED,
              color: DARK_INTERACTIVE_PRIMARY_PRESSED,
            },
          },
          ':hover': {
            backgroundColor: DARK_INTERACTIVE_SECONDARY_HOVER,
            color: DARK_INTERACTIVE_PRIMARY_HOVER,
          },
          ':active': {
            backgroundColor: DARK_INTERACTIVE_SECONDARY_PRESSED,
            color: DARK_INTERACTIVE_PRIMARY_PRESSED,
          },
          '&.Mui-disabled': {
            color: DARK_TEXT_DISABLED,
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          ':hover': {
            color: DARK_INTERACTIVE_PRIMARY_HOVER,
          },
          ':active': {
            color: DARK_INTERACTIVE_PRIMARY_PRESSED,
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: DARK_TEXT_TERTIARY,
          ':hover': {
            color: DARK_INTERACTIVE_PRIMARY_HOVER,
            backgroundColor: DARK_INTERACTIVE_SECONDARY_HOVER,
          },
          ':active': {
            color: DARK_INTERACTIVE_PRIMARY_PRESSED,
            backgroundColor: DARK_INTERACTIVE_SECONDARY_PRESSED,
          },
          '&.Mui-checked': {
            color: DARK_INTERACTIVE_ACTIVE,
            ':hover': {
              color: DARK_INTERACTIVE_PRIMARY_HOVER,
            },
            ':active': {
              color: DARK_INTERACTIVE_PRIMARY_PRESSED,
            },
          },
          '&.Mui-disabled': {
            color: DARK_TEXT_DISABLED,
            '&.Mui-checked': {
              /* pseudo-element necessary here because setting the background-color
              directly on the SVG takes more screen space than the checkbox itself */
              '&::before': {
                content: '""',
                position: 'absolute',
                height: '37%',
                width: '37%',
                top: '32%',
                left: '32%',
                zIndex: -1,
                backgroundColor: DARK_CONTAINER_BACKGROUND,
              },
            },
          },
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          color: DARK_TEXT_SECONDARY,
          border: `1px solid ${DARK_RULES}`,
          '&.Mui-selected': {
            color: DARK_INTERACTIVE_ACTIVE,
            backgroundColor: DARK_INTERACTIVE_SELECTED,
            backgroundClip: 'padding-box',
            ':hover': {
              backgroundColor: DARK_INTERACTIVE_SECONDARY_HOVER,
            },
            border: `1px solid ${DARK_RULES}`,
          },
          ':hover': {
            backgroundColor: DARK_INTERACTIVE_SECONDARY_HOVER,
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          color: DARK_TEXT_SECONDARY,
          backgroundColor: DARK_CONTAINER_BACKGROUND,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        outlined: {
          border: `1px solid ${DARK_RULES}`,
          backgroundColor: DARK_CONTAINER_BACKGROUND,
          color: DARK_TEXT_SECONDARY,
        },
        deletable: {
          ':hover': {
            color: DARK_TEXT_PRIMARY,
            border: `1px solid ${DARK_INTERACTIVE_ACTIVE}`,
            '> .MuiSvgIcon-root': {
              color: DARK_INTERACTIVE_ACTIVE,
              ':hover': {
                color: DARK_INTERACTIVE_PRIMARY_HOVER,
              },
            },
          },
          ':active': {
            backgroundColor: DARK_INTERACTIVE_SECONDARY_PRESSED,
            '> .MuiSvgIcon-root': {
              ':hover': {
                color: DARK_INTERACTIVE_PRIMARY_PRESSED,
              },
            },
          },
        },
        clickable: {
          ':hover': {
            color: DARK_TEXT_PRIMARY,
            border: `1px solid ${DARK_INTERACTIVE_ACTIVE}`,
            backgroundColor: DARK_INTERACTIVE_SECONDARY_HOVER,
            '> .MuiSvgIcon-root': {
              color: DARK_INTERACTIVE_ACTIVE,
              ':hover': {
                color: DARK_INTERACTIVE_PRIMARY_HOVER,
              },
            },
          },
          ':active': {
            backgroundColor: DARK_INTERACTIVE_SECONDARY_PRESSED,
            '> .MuiSvgIcon-root': {
              ':hover': {
                color: DARK_INTERACTIVE_PRIMARY_PRESSED,
              },
            },
          },
        },
        deleteIcon: {
          color: DARK_TEXT_TERTIARY,
          ':hover': {
            color: DARK_INTERACTIVE_ACTIVE,
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: DARK_RULES,
        },
        withChildren: {
          '&::before': {
            borderColor: DARK_RULES,
          },
          '&::after': {
            borderColor: DARK_RULES,
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          ':hover': {
            backgroundColor: DARK_INTERACTIVE_SECONDARY_HOVER,
          },
          '& .MuiTouchRipple-child': {
            backgroundColor: DARK_INTERACTIVE_PRIMARY_PRESSED,
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          backgroundColor: DARK_CONTAINER_BACKGROUND,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '> .MuiSvgIcon-root:not(.Mui-disabled)': {
            color: DARK_TEXT_SECONDARY,
          },
          '> .MuiSvgIcon-root.Mui-disabled:not(.Mui-error)': {
            fill: DARK_TEXT_DISABLED,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          // Font style for placeholder text when input is in focus but no value has been added
          fontSize: '0.875rem',
          fontWeight: 400,
          lineHeight: '1.125rem',
          '&.MuiOutlinedInput-root:not(.Mui-error):not(.Mui-disabled)': {
            ':hover:not(.Mui-focused) > fieldset': {
              // Set border color on hover, but not when focused
              borderColor: DARK_RULES,
            },
            ':active > fieldset': {
              // Keep border color from changing when holding down mouse click on field
              borderColor: DARK_INTERACTIVE_ACTIVE,
            },
          },
          '&.Mui-error .MuiOutlinedInput-notchedOutline': {
            // Set border color on error
            borderColor: DARK_TEXT_NEGATIVE,
          },
          '&.MuiOutlinedInput-root.Mui-disabled > fieldset': {
            borderColor: DARK_TEXT_DISABLED,
          },
          '&.MuiOutlinedInput-root.Mui-disabled:not(.Mui-error)': {
            '& > input': {
              color: DARK_TEXT_DISABLED,
              WebkitTextFillColor: DARK_TEXT_DISABLED,
            },
          },
        },
        notchedOutline: {
          borderColor: DARK_RULES,
        },
        input: {
          '::placeholder': {
            color: DARK_TEXT_TERTIARY,
          },
          color: DARK_TEXT_PRIMARY,
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          color: DARK_TEXT_TERTIARY,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: DARK_TEXT_SECONDARY,
          '&.Mui-error': {
            color: DARK_TEXT_NEGATIVE,
          },
          '&.Mui-disabled': {
            color: DARK_TEXT_DISABLED,
          },
        },
        filled: {
          // Text color of value in input
          '& + div.MuiOutlinedInput-root': {
            color: DARK_TEXT_PRIMARY,
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: DARK_TEXT_TERTIARY,
          '&.Mui-error': {
            color: DARK_TEXT_NEGATIVE,
          },
          '&.Mui-disabled': {
            color: DARK_TEXT_DISABLED,
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: DARK_CONTAINER_BACKGROUND,
          color: DARK_TEXT_PRIMARY,
          '& .MuiTextField-root': {
            backgroundColor: 'transparent',
          },
          '& .MuiDateRangeCalendar-root': {
            backgroundColor: DARK_CONTAINER_BACKGROUND,
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          border: `1px solid ${DARK_CONTAINER_BORDERS}`,
          '.driver-card:hover': {
            borderColor: DARK_TEXT_SECONDARY,
          },
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          color: DARK_TEXT_PRIMARY,
        },
        action: {
          alignSelf: 'unset',
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          '& span.MuiTypography-secondary': {
            color: DARK_TEXT_TERTIARY,
          },
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          color: DARK_TEXT_SECONDARY,
          ':hover': {
            backgroundColor: DARK_INTERACTIVE_SECONDARY_HOVER,
          },
          '&.Mui-selected': {
            backgroundColor: DARK_INTERACTIVE_SELECTED,
          },
        },
      },
    },
    MuiDateRangeCalendar: {
      styleOverrides: {
        root: {
          border: `1px solid ${DARK_RULES}`,
        },
      },
    },
    MuiDayCalendar: {
      styleOverrides: {
        header: {
          color: DARK_TEXT_SECONDARY,
        },
        weekDayLabel: {
          color: DARK_TEXT_SECONDARY,
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          color: DARK_TEXT_SECONDARY,
          ':hover': {
            backgroundColor: DARK_INTERACTIVE_SECONDARY_HOVER,
          },
          '&.Mui-disabled:not(.Mui-selected)': {
            color: DARK_TEXT_DISABLED,
          },
          '&.Mui-selected': {
            color: DARK_TEXT_BUTTON,
            ':hover': {
              backgroundColor: DARK_INTERACTIVE_PRIMARY_HOVER,
            },
            ':focus': {
              backgroundColor: DARK_INTERACTIVE_PRIMARY_PRESSED,
            },
          },
        },
      },
    },
    MuiDateRangePickerDay: {
      styleOverrides: {
        rangeIntervalDayHighlight: {
          backgroundColor: DARK_INTERACTIVE_SELECTED,
        },
      },
    },
    MuiMultiSectionDigitalClockSection: {
      styleOverrides: {
        item: {
          '&.Mui-selected': {
            backgroundColor: DARK_INTERACTIVE_ACTIVE,
            color: DARK_TEXT_BUTTON,
            ':hover': {
              backgroundColor: DARK_INTERACTIVE_PRIMARY_HOVER,
            },
          },
          ':hover': {
            backgroundColor: DARK_INTERACTIVE_PRIMARY_HOVER,
            color: DARK_TEXT_BUTTON,
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          '&.Mui-disabled': {
            WebkitTextFillColor: DARK_TEXT_DISABLED,
          },
        },
      },
    },
    MuiSnackbar: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
          '.MuiAlert-root': {
            color: LIGHT_TEXT_PRIMARY,
            backgroundColor: LIGHT_MODAL_BACKGROUND,
          },
          '.MuiAlert-action': {
            '.MuiIconButton-root': {
              color: LIGHT_TEXT_PRIMARY,
              ':hover': {
                backgroundColor: 'transparent',
              },
            },
          },
          '.MuiAlert-standardError': {
            '.MuiAlert-icon': {
              color: LIGHT_NEGATIVE,
            },
          },
          '.MuiAlert-standardInfo': {
            '.MuiAlert-icon': {
              color: LIGHT_INFO,
            },
          },
          '.MuiAlert-standardSuccess': {
            '.MuiAlert-icon': {
              color: LIGHT_POSITIVE,
            },
          },
        },
      },
    },
    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          backgroundColor: LIGHT_MODAL_BACKGROUND,
          color: LIGHT_TEXT_PRIMARY,
        },
        action: {
          '& > button > svg': { fill: LIGHT_TEXT_SECONDARY },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        standard: {
          color: DARK_TEXT_PRIMARY,
        },
        standardError: {
          backgroundColor: DARK_NEGATIVE_BACKGROUND,
          '.MuiAlert-action': {
            ' button:hover': {
              backgroundColor: DARK_INTERACTIVE_NEGATIVE_HOVER,
            },
          },
          '.MuiAlert-icon': {
            color: DARK_FEEDBACK_NEGATIVE,
          },
        },
        standardWarning: {
          backgroundColor: DARK_CAUTION_BACKGROUND,
          '.MuiAlert-action': {
            ' button:hover': {
              backgroundColor: DARK_INTERACTIVE_CAUTION_HOVER,
            },
          },
          '.MuiAlert-icon': {
            color: DARK_FEEDBACK_CAUTION,
          },
        },
        standardInfo: {
          backgroundColor: DARK_INFO_BACKGROUND,
          '.MuiAlert-action': {
            ' button:hover': {
              backgroundColor: DARK_INTERACTIVE_INFO_HOVER,
            },
          },
          '.MuiAlert-icon': {
            color: DARK_FEEDBACK_INFO,
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: DARK_TEXT_SECONDARY,
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: DARK_MODAL_BACKGROUND,
          backgroundImage: 'none',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          backgroundColor: DARK_MODAL_BACKGROUND,
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          backgroundColor: DARK_MODAL_BACKGROUND,
          backgroundImage: 'none',
        },
      },
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          color: DARK_TEXT_PRIMARY,
        },
      },
    },
  },
} as ThemeOptions);
